@font-face {
    font-family: CircularStd-Book;
    src: url("/assets/fonts/CircularStd-Book.otf");
    font-display: swap;
}
@font-face {
    font-family: CircularStd-Bold;
    src: url("/assets/fonts/CircularStd-Bold.otf");
    font-display: swap;
}

.btn-pink {
    background-color: #f51b6f;
    color: white;
    border-radius: 13px;
    padding: 0.8rem 2rem;
}

a.btn-pink{
    &:hover{
        color: white;
        background-color: #b10949;
    }
}

body {
   font-family: CircularStd-Book;
}

h1, h2{
    font-weight: bold;
}

h2{
    font-family: CircularStd-Bold;
    font-size: 3rem;
}

strong{
    text-transform: uppercase;
    font-family: "CircularStd-Black";
}

.break{
    word-spacing:999px;
}

.container, .container-fluid{
    margin-top: 6rem;
}


img{
    animation: fadeInAnimation ease 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}


a.video {
    display: block;
     overflow: hidden;

     img{
        transition: 0.7s;
     }

     &:hover{
        img{
            transform: scale(1.1);
        }         
     }
}